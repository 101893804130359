import { CellClassParams } from '@ag-grid-community/core';
import { AspireRecord } from '@models/aspireRecord';
import { DestroyedDrugRecord } from '@services/pharmacy-demo/models/DestroyedDrugRecord';
import { DispensedDrugRecord } from '@services/pharmacy-demo/models/DispensedDrugRecord';
import { GlobalRecord } from '@services/pharmacy-demo/models/GlobalRecord';

export const pctFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const cellClassRuleHelperAspireRecord = {
  recalledDrug: (params: CellClassParams<AspireRecord>) => (params.data?.status ?? '') === 'Expiration Risk',
  expiredDrug: (params: CellClassParams<AspireRecord>) => (params.data?.status ?? '') === 'Expired',
  restockDrug: (params: CellClassParams<AspireRecord>) => (params.data?.status ?? '') === 'Restock',
  returnDrug: (params: CellClassParams<AspireRecord>) => (params.data?.status ?? '') === 'Return',
  relocateDrug: (params: CellClassParams<AspireRecord>) => (params.data?.status ?? '') === 'Relocate',
  inStockDrug: (params: CellClassParams<AspireRecord>) => (params.data?.status ?? '') === 'In Stock'
};

export const cellClassRuleHelperGlobalRecord = {
  recalledDrug: (params: CellClassParams<GlobalRecord>) => (params.data?.status ?? '') === 'Recalled',
  expiredDrug: (params: CellClassParams<GlobalRecord>) => (params.data?.status ?? '') === 'Expired',
  restockDrug: (params: CellClassParams<GlobalRecord>) => (params.data?.status ?? '') === 'Restock',
  returnDrug: (params: CellClassParams<GlobalRecord>) => (params.data?.status ?? '') === 'Return',
  relocateDrug: (params: CellClassParams<GlobalRecord>) => (params.data?.status ?? '') === 'Relocate',
  inStockDrug: (params: CellClassParams<GlobalRecord>) => (params.data?.status ?? '') === 'In Stock'
};

export const cellClassRuleHelperDestroyedDrugRecord = {
  recalledDrug: (params: CellClassParams<DestroyedDrugRecord>) => (params.data?.status ?? '') === 'Recalled',
  expiredDrug: (params: CellClassParams<DestroyedDrugRecord>) => (params.data?.status ?? '') === 'Expired',
  restockDrug: (params: CellClassParams<DestroyedDrugRecord>) => (params.data?.status ?? '') === 'Restock',
  returnDrug: (params: CellClassParams<DestroyedDrugRecord>) => (params.data?.status ?? '') === 'Return',
  relocateDrug: (params: CellClassParams<DestroyedDrugRecord>) => (params.data?.status ?? '') === 'Relocate',
  inStockDrug: (params: CellClassParams<DestroyedDrugRecord>) => (params.data?.status ?? '') === 'In Stock'
};

export const cellClassRuleHelperDispensedDrugRecord = {
  recalledDrug: (params: CellClassParams<DispensedDrugRecord>) => (params.data?.status ?? '') === 'Recalled',
  expiredDrug: (params: CellClassParams<DispensedDrugRecord>) => (params.data?.status ?? '') === 'Expired',
  restockDrug: (params: CellClassParams<DispensedDrugRecord>) => (params.data?.status ?? '') === 'Restock',
  returnDrug: (params: CellClassParams<DispensedDrugRecord>) => (params.data?.status ?? '') === 'Return',
  relocateDrug: (params: CellClassParams<DispensedDrugRecord>) => (params.data?.status ?? '') === 'Relocate',
  inStockDrug: (params: CellClassParams<DispensedDrugRecord>) => (params.data?.status ?? '') === 'In Stock'
};
