export type Tenants = '' | 'HCA' | 'RegionalOneHealth' | 'aspire' | 'advantus';

export type TenantInfo = {
  tenant: Tenants;
  path: string;
};

export const BlankTenant: TenantInfo = {
  tenant: '',
  path: ''
};

export const tenantPaths: TenantInfo[] = [
  { tenant: 'HCA', path: '/hca' },
  { tenant: 'aspire', path: '/aspire' },
  {
    tenant: 'RegionalOneHealth',
    path: '/regionalonehealth'
  }, {
    tenant: 'advantus',
    path: '/advantus'
  }
];
